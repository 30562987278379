import "../styles/Auth.css";
import Spacer from "../components/Spacer";
import "react-toastify/dist/ReactToastify.css";
import LoadingScreen from "../components/LoadingScreen";
import { useState } from "react";
import { auth, userIsAdmin } from "../firebase";
import { User, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { ToastContainer, ToastPosition, Theme, toast } from "react-toastify";

export interface ToastStyle {
  position: ToastPosition;
  autoClose: number;
  hideProgressBar: boolean;
  closeOnClick: boolean;
  pauseOnHover: boolean;
  draggable: boolean;
  progress: undefined;
  theme: Theme;
}

export const toastStyle: ToastStyle = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
};

/*
 */
export default function Auth({
  setUser,
}: {
  setUser: React.Dispatch<React.SetStateAction<User | null | undefined>>;
}) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleLogin = async () => {
    setLoading(true);
    try {
      const { user } = await signInWithEmailAndPassword(auth, email, password);

      // error 1: user email isn't verified
      if (!user.emailVerified) {
        signOut(auth);
        toast.error(
          "That email/password combination couldn't be found! ☹️",
          toastStyle
        );
      } else if (!(await userIsAdmin(user.uid))) {
        signOut(auth);
        toast.error(
          "Unforunately, RedRoverWeb is only available for poll creators (for now) 😬",
          toastStyle
        );
      } else {
        setUser(user);
      }
    } catch (error) {
      // error 2: passwords don't match
      toast.error(
        "That email/password combination couldn't be found! ☹️",
        toastStyle
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div id="AuthParent">
      <LoadingScreen visible={loading} />
      <h1 className="HeaderText">Login</h1>
      <Spacer height={"7.5vh"} />
      <input
        className="AuthInput"
        type="text"
        value={email}
        placeholder="email"
        onChange={(e) => setEmail(e.target.value)}
      />
      <Spacer height={"2.5vh"} />
      <input
        className="AuthInput"
        type="password"
        value={password}
        placeholder="password"
        onKeyUp={({ key }) => {
          if (key === "Enter") handleLogin();
        }}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Spacer height={"7.5vh"} />
      <button
        id="AuthSubmitButton"
        onClick={handleLogin}
        style={{ opacity: email === "" || password === "" ? 0 : 1 }}
      >
        <h1 id="AuthSubmitButtonText">Login</h1>
      </button>
      <ToastContainer
        limit={1}
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        style={{ width: "25vmax" }}
      />
    </div>
  );
}
