import React from "react";
import { PieChart } from "react-minimal-pie-chart";

interface PieChartProps {
  data: any[];
  label: string;
}

const PieChartComponent: React.FC<PieChartProps> = ({ data, label }) => {
  // Prepare the data in the format needed by react-minimal-pie-chart
  const chartData = data.map((item) => {
    console.log("Number: ", item.userID.length);
    return {
      title: item[label],
      value: item.userID.length, // Assuming userID is an array of user IDs
      color: "#" + (((1 << 24) * Math.random()) | 0).toString(16), // Generate a random color for each demographic value
    };
  });

  return (
    <PieChart
      animate={true}
      animationDuration={500}
      animationEasing="ease-out"
      data={chartData}
      label={({ dataEntry }) => dataEntry.title}
      labelPosition={50}
      labelStyle={{
        fontSize: "5px",
        fontFamily: "sans-serif",
        fill: "#121212",
      }}
      lengthAngle={360}
      lineWidth={100}
      onClick={undefined}
      onMouseOut={undefined}
      onMouseOver={undefined}
      paddingAngle={0}
      radius={50}
      startAngle={0}
      viewBoxSize={[100, 100]}
      style={{
        height: "200px",
        width: "200px",
      }}
    />
  );
};

export default PieChartComponent;
