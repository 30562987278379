import Typed from "typed.js";
import "../styles/AdminLoginScreen.css";
import { auth } from "../firebase";
import { useRef, useState, useEffect } from "react";
import { RecaptchaVerifier, User, signInWithPhoneNumber } from "firebase/auth";
import Spacer from "../components/Spacer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingScreen from "../components/LoadingScreen";

/*
  This is the screen that is shown when somebody navigates to the main site 
  url (/). For now, this allows an admin to login using their phone number
  and a one-time password. This only works for approved RedRover users that 
  are modified by hand in the firestore database.

  The code is bulky, because there's some helper functions that are written 
  for the captcha logic. We can move this later if necessary, but right now
  it just makes state/type management a tad bit easier.
*/
export default function AdminLoginScreen() {
  const [otp, setOtp] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<User | null>(); // eslint-disable-line @typescript-eslint/no-unused-vars

  const [strings, setStrings] = useState<string[]>([
    "Hello,  Alex^5000",
    "Hello,  Griffin^5000",
    "Hello,  Angel^5000",
    "Hello,  Brandon^5000",
    "Hello,  Vasco^5000",
  ]);

  const textRef = useRef(null);
  const animationRef = useRef<any>(null);

  // hook for the typed.js library that does the typing animation for
  // the names we see on screen
  useEffect(() => {
    const options = {
      strings,
      typeSpeed: 50,
      backSpeed: 50,
      smartBackspace: true,
      ...(strings.length === 1 ? {} : { loop: true, loopCount: Infinity }),
    };

    animationRef.current = new Typed(textRef.current, options);

    // Make sure to destroy Typed instance during cleanup
    // to prevent memory leaks
    return () => {
      animationRef.current.destroy();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // TODO: fix this logic for switching the typing message in this hook
  // this works decently for now, but doesn't always work as intended.
  // luckily, the animation still looks nice and the user can't really
  // notice.
  useEffect(() => {
    setOtpSent(strings.length === 1);
  }, [strings]);

  // this is necessary for the sign-in handler. this keeps the captcha
  // information in the window to make it persist in the browser.
  const onCaptchaVerify = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
        },
        auth
      );
    }
  };

  // this is the callback that is triggered when a user
  // initiates a sign-in by entering their phone and
  // has an OTP sent to them.
  const onSignIn = () => {
    setLoading(true);
    onCaptchaVerify();
    const appVerifier = window.recaptchaVerifier;

    // ctrl + click to see this functions implementation in
    // the firebase.ts file. after it runs, it changes the
    // typed message on the homepage and shows a corresponding
    // toast message.
    signInWithPhoneNumber(auth, `+1${phoneNum}`, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        toast.success("We've sent you an OTP! 📲", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setStrings(["Please enter your OTP"]);
      })
      .catch(() => {
        toast.error(
          "Uh oh! There was a problem with that number. Please try again!",
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
      })
      .finally(() => setLoading(false));
  };

  // this is the callback function that runs when a user submits
  // an OTP.
  function onOTPVerify() {
    setLoading(true);

    // use the captcha variable in the window to verify
    // the OTP safely. after it's done, we simply set our
    // corresponding state variables and show an error
    // message, if necessary.
    window.confirmationResult
      .confirm(otp)
      .then(async (res: any) => {
        setUser(res.user);
        setLoading(false);
        window.location.href = "/";
      })
      .catch(() => {
        toast.error(
          "Uh oh! There was a problem with that OTP. Please try again!",
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
      });
  }

  return (
    <div id="AdminLoginContainer">
      {/* 
        the loading screen and captcha container are both 
        invisible.
      */}
      <LoadingScreen visible={loading} />
      <div id="recaptcha-container" />

      {/* 
        this holds the text that is being animated with the 
        typing animation. the animation is managed through 
        the textRef variable in the span's ref prop.
      */}
      <div>
        <span id="AdminLoginScreenTyping" ref={textRef} />
      </div>

      <div id="AdminGrowingSpacer" />

      {/* 
        we need to make sure to render different input boxes and 
        buttons depending if the user has been sent an OTP or not.
        technically, we can choose to render only the button 
        conditionally, but then the state variables for the input
        would need to be rendered conditionally in that case. 
      */}
      {otpSent ? (
        <>
          <input
            placeholder="OTP"
            className="AdminLoginInput"
            type="text"
            value={otp}
            onKeyUp={({ key }) => {
              if (key === "Enter") onOTPVerify();
            }}
            onChange={({ target }) => setOtp(target.value)}
          />
          <Spacer width="100vw" height="5vh" />
          <button id="AdminLoginScreenButton" onClick={onOTPVerify}>
            <p id="AdminLoginButtonText">Next</p>
          </button>
        </>
      ) : (
        <>
          <input
            placeholder="phone"
            className="AdminLoginInput"
            type="text"
            value={phoneNum}
            onKeyUp={({ key }) => {
              if (key === "Enter") onSignIn();
            }}
            onChange={({ target }) => setPhoneNum(target.value)}
          />
          <Spacer width="100vw" height="5vh" />
          <button id="AdminLoginScreenButton" onClick={onSignIn}>
            <p id="AdminLoginButtonText">Next</p>
          </button>
        </>
      )}

      {/*
        this also has an absolute invisible posiiton, and simply holds the 
        toast messages that pop up at the top of the screen.
      */}
      <ToastContainer
        limit={1}
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
}
