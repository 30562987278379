import "../styles/HomeScreen.css";
import EmployeeScreen from "./EmployeeScreen";
import LoadingScreen from "../components/LoadingScreen";
import { User } from "firebase/auth";
import { useEffect, useState } from "react";
import { auth, userIsSuperAdmin } from "../firebase";

/*
  This screen is the homepage, but is only truly accessible once someone is logged
  into an employee account. For this reason, we do a series of checks to make sure 
  that we only allow employees and redirect them to the login page when no user
  is authenticated.
*/
function HomeScreen() {
  const [user, setUser] = useState<User | null>();
  const [loadingData, setLoadingData] = useState(false);
  const [isEmployee, setIsEmployee] = useState<boolean>();

  useEffect(() => {
    auth.onAuthStateChanged((currUser) => {
      setUser(currUser === null ? null : currUser);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user !== null && user !== undefined) {
      setLoadingData(true);
      userIsSuperAdmin(user.uid)
        .then((result) => {
          setIsEmployee(result ? true : false);
          setLoadingData(false);
        })
        .catch((err) => {
          console.log(err);
          setUser(null);
          setLoadingData(false);
        });
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  if (user === null) {
    window.location.href = "/login";
    return <></>;
  }

  return (
    <div id="HomeScreenContainer">
      <LoadingScreen visible={loadingData || user === undefined} />
      {isEmployee !== undefined &&
        (() => {
          return <EmployeeScreen setUser={setUser} isEmployee={isEmployee} />;

          // TODO: implement some sort of error screen.
          // else return <CreatorScreen publishedPolls={publishedPolls} />;
        })()}
    </div>
  );
}

export default HomeScreen;
