import { BallTriangle } from "react-loading-icons";
import "../styles/LoadingScreen.css";

export default function LoadingScreen({ visible }: { visible: boolean }) {
  return (
    <div
      id="LoadingScreen"
      style={{ opacity: visible ? 1 : 0, zIndex: visible ? 2 : -1 }}
    >
      <BallTriangle
        fill="#853b30"
        stroke="#853b30"
        width={"10vmax"}
        height={"10vmax"}
      />
    </div>
  );
}
