export default function Spacer({
  width,
  height,
}: {
  width?: number | string;
  height?: number | string;
}) {
  return (
    <div
      style={{
        width: width !== undefined ? width : "100%",
        height: height !== undefined ? height : "5vh",
      }}
    ></div>
  );
}
