import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  limit,
  getDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import {
  AFFILIATION,
  EDUCATION_LEVEL,
  GENDER,
  INCOME_LEVEL,
  JOB,
  LS,
  PET,
  RACE,
} from "./constants/demographics";
import { COUNTRY } from "./constants/localization";

/* 
  Most of these types and calls are directly ripped from
  "App/firebase.ts". We could've simply imported it, but there
  is a requirement for npm linking, moving the files, and so 
  on. In the future we can change this, but it's better to simply
  keep them separate for the sake of usability and the scope of
  the project.
*/

/*----------------------Types----------------------*/
interface PollDraftInfo {
  id: string;
  additionalInfo: string;
  dateCreated: string;
  description: string;
  title: string;
}
interface QuestionData {
  category: "Multiple Choice" | "Free Response" | "Ranking" | "Range (Slider)";
  question: string;
  answers: string[];
  letterCount?: number;
  wordCount?: number;
  multiline?: boolean;
  minRange?: number;
  maxRange?: number;
  dollarSign?: boolean;
}
interface QuestionDataWithID extends QuestionData {
  id: string;
}
interface ExtendedQuestion extends QuestionDataWithID {
  votes: { answer: string; userID: string }[];
}
interface PublishedPollData {
  author: string;
  createdAt: string;
  expirationTime: string;
  pollData: PollDraftInfo;
  questions: ExtendedQuestion[];
  likes: { userID: string }[];
}
interface PublishedPollWithID extends PublishedPollData {
  id: string;
}

interface PayoutInfo {
  [key: string]: {
    alias: string;
    pollsPublished: number;
    dollarsPaidOut: number;
  };
}

interface PayoutInfoListItem {
  author: string;
  alias: string;
  pollsPublished: number;
  dollarsPaidOut: number;
}

// interface FilteredInfo {
//   school: { school: string; votes: number; likes: number }[];
//   educationLevel: {
//     educationLevel: EDUCATION_LEVEL;
//     votes: number;
//     likes: number;
//   }[];
//   country: { country: COUNTRY; votes: number; likes: number }[];
//   state: { state: string; votes: number; likes: number }[];
//   city: { city: string; votes: number; likes: number }[];
//   gender: { gender: GENDER; votes: number; likes: number }[];
//   race: { race: RACE; votes: number; likes: number }[];
//   hispanicOrLatino: {
//     hispanicOrLatino: boolean;
//     votes: number;
//     likes: number;
//   }[];
//   politicalAffiliation: {
//     politicalAffiliation: AFFILIATION;
//     votes: number;
//     likes: number;
//   }[];
//   lgbtq: { lbgtq: boolean; votes: number; likes: number }[];
//   incomeLevel: { incomeLevel: INCOME_LEVEL; votes: number; likes: number }[];
//   occupation: { occupation: JOB; votes: number; likes: number }[];
//   religion: { religion: string; votes: number; likes: number }[];
//   pet: { pet: PET; votes: number; likes: number }[];
//   livingSituation: { livingSituation: LS; votes: number; likes: number }[];
// }

// THIS IS TEMPORARY, just while I figure out what's going wrong w the typing
// of the real interface above
interface FilteredInfo {
  [key: string]: any[];
}

// interface FilteredQuestionInfo {
//   school: { school: string; answerTallies: string[] }[];
//   educationLevel: {
//     educationLevel: EDUCATION_LEVEL;
//     answerTallies: string[];
//   }[];
//   country: { country: COUNTRY; answerTallies: string[] }[];
//   state: { state: string; answerTallies: string[] }[];
//   city: { city: string; answerTallies: string[] }[];
//   gender: { gender: GENDER; answerTallies: string[] }[];
//   race: { race: RACE; answerTallies: string[] }[];
//   hispanicOrLatino: {
//     hispanicOrLatino: boolean;
//     answerTallies: string[];
//   }[];
//   politicalAffiliation: {
//     politicalAffiliation: AFFILIATION;
//     answerTallies: string[];
//   }[];
//   lgbtq: { lbgtq: boolean; answerTallies: string[] }[];
//   incomeLevel: {
//     incomeLevel: INCOME_LEVEL;
//     answerTallies: string[];
//   }[];
//   occupation: { occupation: JOB; answerTallies: string[] }[];
//   religion: { religion: string; answerTallies: string[] }[];
//   pet: { pet: PET; answerTallies: string[] }[];
//   livingSituation: {
//     livingSituation: LS;
//     answerTallies: string[];
//   }[];
// }
interface FilteredQuestionInfo {
  [key: string]: any;
}

/*-------------------------------------------------*/

/*----------------------Setup----------------------*/
let moment = require("moment-timezone");

const firebaseConfig = {
  apiKey: "AIzaSyBVbrOgLXw-zGVNr_1E20eXJEXvh0Cr-Yo",
  authDomain: "redrover-2c5f6.firebaseapp.com",
  projectId: "redrover-2c5f6",
  storageBucket: "redrover-2c5f6.appspot.com",
  messagingSenderId: "720603590863",
  appId: "1:720603590863:web:1b30adab72de6e318987ba",
  measurementId: "G-Q0T1W8LRWK",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth();
auth.languageCode = "it";

const db = getFirestore(app);
/*-------------------------------------------------*/

/*-----------------User Data Calls-----------------*/

/* 
  Query for the user's id in the Users collection and return 
  if they're an admin or not.
*/
const userIsAdmin = async (uid: string) => {
  const q = query(collection(db, "users"), where("id", "==", uid), limit(1));
  const isAdmin = (await getDocs(q)).docs[0].data().admin;
  return isAdmin;
};

/* 
  Query for the user's id in the Users collection and return 
  if they're a privileged RedRover employee.
*/
const userIsSuperAdmin = async (uid: string) => {
  const q = query(collection(db, "users"), where("id", "==", uid), limit(1));
  const isSuperAdmin = (await getDocs(q)).docs[0].data().rrAdmin;
  return isSuperAdmin;
};

/* 
  Query for the user's id in the Users collection and return 
  their data that's held in the collection.
*/
const getUserData = async (uid: string) => {
  const q = query(collection(db, "users"), where("id", "==", uid), limit(1));
  return (await getDocs(q)).docs[0].data();
};

/*
  This gets all of the users from the DB and returns them in a list.
  This is to be used for the employee screen only.
*/
const getAllUsersData = async (): Promise<FilteredInfo> => {
  const q = collection(db, "users");
  const userDocs = await getDocs(q);
  const allUsers = userDocs.docs.map(doc => doc.data());

  const data: FilteredInfo = {
    city: [],
    country: [],
    educationLevel: [],
    gender: [],
    hispanicOrLatino: [],
    incomeLevel: [],
    lgbtq: [],
    livingSituation: [],
    occupation: [],
    pet: [],
    politicalAffiliation: [],
    race: [],
    religion: [],
    school: [],
    state: [],
  };

  // Process each user's demographic info and populate the data structure
  for (const user of allUsers) {
    for (const key in data) {
      let demographicValue = user[key];

      // Special handling for 'hispanicOrLatino' and 'lgbtq' keys
      if (key === 'hispanicOrLatino') {
        demographicValue = demographicValue ? 'Hispanic' : 'Not Hispanic';
      } else if (key === 'lgbtq') {
        demographicValue = demographicValue ? 'Lgbtq' : 'Not Lgbtq';
      }

      // Check if the demographic value already exists in the data
      if (demographicValue !== undefined && demographicValue !== null) {
        const existingIndex = data[key].findIndex(item => item[key] === demographicValue);
        
        if (existingIndex === -1) {
          const newItem: any = {};
          newItem[key] = demographicValue;
          newItem['userID'] = [user.id]; // Ensure this is an array with a single user ID
          data[key].push(newItem);
        } else {
          data[key][existingIndex].userID.push(user.id); // Append the user ID to the existing array
        }
      }
    }
  }

  return data;
};

/* 
  This gets all of the users seeking approval to be poll 
  creators. This is to be used for the employee screen 
  only.
*/
const getUsersSeekingApproval = async () => {
  const q = query(
    collection(db, "users"),
    where("seekingApproval", "==", true)
  );
  const docList = (await getDocs(q)).docs;
  if (docList.length > 0) return docList.map((doc) => doc.data());
  return [];
};

// This function puts total number of votes and likes into demographic categories.
const getFilterData = async (
  votes: {
    answer: string;
    userID: string;
  }[],
  likes: { userID: string }[]
): Promise<FilteredInfo> => {
  const data: FilteredInfo = {
    city: [],
    country: [],
    educationLevel: [],
    gender: [],
    hispanicOrLatino: [],
    incomeLevel: [],
    lgbtq: [],
    livingSituation: [],
    occupation: [],
    pet: [],
    politicalAffiliation: [],
    race: [],
    religion: [],
    school: [],
    state: [],
  };

  const vote_userIDs = votes.map((vote) => vote.userID);
  const like_userIDs = likes.map((like) => like.userID);

  // Function for processing votes/likes.
  const process = (userData: any, type: string) => {
    // Add this user's vote/like into demographic categories. Here, "key" is the category, e.g. "city".
    for (const key in data) {
      // filter value: e.g. "San Francisco".
      const filterValue = userData[key];
      // indexOfFilter is an indicator for whether e.g. { city: "San Francisco"; votes: any; likes: any } is present in city: [].
      let indexOfFilter: any = data[key].findIndex(
        (item: any) => item[key] === filterValue
      );
      if (indexOfFilter === -1) {
        const item: any = { votes: 0, likes: 0 };
        item[key] = filterValue;
        data[key].push(item);
        indexOfFilter = data[key].findIndex(
          (item: any) => item[key] === filterValue
        );
      }
      if (type === "votes") {
        data[key][indexOfFilter].votes++;
      } else {
        data[key][indexOfFilter].likes++;
      }
    }
  };

  // Process votes first.
  for (const userID of vote_userIDs) {
    // Retrieve user data from firebase.
    const q = query(
      collection(db, "users"),
      where("id", "==", userID),
      limit(1)
    );
    const docID = (await getDocs(q)).docs[0].id;
    const userData = (await getDoc(doc(db, `users/${docID}`))).data();
    process(userData, "votes");
  }

  // Process likes next.
  for (const userID of like_userIDs) {
    // Retrieve user data from firebase.
    const q = query(
      collection(db, "users"),
      where("id", "==", userID),
      limit(1)
    );
    const docID = (await getDocs(q)).docs[0].id;
    const userData = (await getDoc(doc(db, `users/${docID}`))).data();
    process(userData, "likes");
  }
  return data;
};

const getFilterDataForQuestion = async (question: ExtendedQuestion) => {
  const filteredInfo: FilteredQuestionInfo = {
    city: [],
    country: [],
    educationLevel: [],
    gender: [],
    hispanicOrLatino: [],
    incomeLevel: [],
    lgbtq: [],
    livingSituation: [],
    occupation: [],
    pet: [],
    politicalAffiliation: [],
    race: [],
    religion: [],
    school: [],
    state: [],
  };

  for (const vote of question.votes) {
    const { answer, userID } = vote;
    const q = query(
      collection(db, "users"),
      where("id", "==", userID),
      limit(1)
    );

    const userData = (await getDocs(q)).docs[0].data();
    for (const key in filteredInfo) {
      const foundIndex = filteredInfo[key].findIndex(
        (item: any) => item[key] === userData[key]
      );
      if (foundIndex === -1) {
        const toPush: any = {};
        toPush[key] = userData[key];
        toPush.answerTallies = [answer];
        filteredInfo[key].push(toPush);
      } else filteredInfo[key][foundIndex].answerTallies.push(answer);
    }
  }

  return filteredInfo;
};

const getAllPollCreators = async () => {
  const q = query(collection(db, "users"), where("admin", "==", true));
  const docList = (await getDocs(q)).docs;
  if (docList.length > 0) return docList.map((doc) => doc.data());
  return [];
};
/*-------------------------------------------------*/

/*-----------------User Poll Calls-----------------*/

/* 
  This turns the data from the document in the DB to a typed
  object so we can have type hints and see all of the attributes
  that we can access in the object.
*/
const parsePublishedPoll = (doc: any) => {
  const { author, createdAt, expirationTime, pollData, questions, likes } =
    doc.data();
  const toRet: PublishedPollWithID = {
    author,
    createdAt,
    expirationTime,
    pollData,
    questions,
    likes,
    id: doc.id,
  };
  return toRet;
};

/*  
  This gets the published polls for a user, given their ID. Each published
  poll is represented by, and converted to a PublishedPollWithID.
*/
const getPublishedPollsForUser = async (userID: string) => {
  // scrape the DB to get the users polls from the corresponding collection
  const currTime = moment();
  const docs = (await getDocs(collection(db, `${userID}_published`))).docs;
  const docRefIDs = docs.map((document) => document.data().publishedDocRef);
  const publishedPolls: PublishedPollWithID[] = [];

  // loop over all of the document references, parse them, and add them into
  // our return list IF they're not expired
  for (const id of docRefIDs) {
    // overrride type to 'any' in order to avoid TS error
    const docRef: any = await getDoc(doc(db, `published/${id}`));
    const { status, expirationTime } = docRef.data();
    if (status !== "expired") {
      if (currTime.diff(moment(parseInt(expirationTime)), "milliseconds") >= 0)
        await updateDoc(doc(db, `published/${id}`), { status: "expired" });
      else publishedPolls.push(parsePublishedPoll(docRef));
    }
  }

  return publishedPolls;
};

/* 
  This gets all of the polls that a user has published, both expired and
  non-expired. This will be useful for displaying results down the line.
*/
const getAllUserPolls = async (userID: string) => {
  const docs = (await getDocs(collection(db, `${userID}_published`))).docs;
  const docRefIDs = docs.map((document) => document.data().publishedDocRef);

  const publishedPolls: PublishedPollWithID[] = [];
  for (const refID of docRefIDs) {
    const docRef = await getDoc(doc(db, `published/${refID}`));
    const publishedPoll = parsePublishedPoll(docRef);
    publishedPolls.push(publishedPoll);
  }

  return publishedPolls;
};

const getPublishedPollByID = async (pollID: string) => {
  const docRef = await getDoc(doc(db, "published", pollID));
  if (docRef.exists()) return parsePublishedPoll(docRef);
  return undefined;
};

/*-------------------------------------------------*/

/*-------------------Admin Stuff-------------------*/

/*
  Approves a user that is seeking approval to become a poll creator.
*/
const approveUser = async (uid: string, alias: string) => {
  const q = query(collection(db, "users"), where("id", "==", uid), limit(1));
  const docRef = (await getDocs(q)).docs[0];
  await updateDoc(doc(db, `users/${docRef.id}`), {
    alias,
    admin: true,
    seekingApproval: false,
  });
};

/* 
  Denies a user that is seeking approval to become a poll creator.
*/
const denyUser = async (uid: string) => {
  const q = query(collection(db, "users"), where("id", "==", uid), limit(1));
  const docRef = (await getDocs(q)).docs[0];
  await updateDoc(doc(db, `users/${docRef.id}`), {
    admin: false,
    seekingApproval: false,
  });
};

/* 
  This gets all of the payout data for a RedRover employee (superAdmin).
  It goes through the published collection once with a query, and tallies
  some stats for each company along the way.
*/
const getPayoutInfo = async () => {
  const docs = (await getDocs(collection(db, "published"))).docs;

  // We first aggregate all of the data into an object so we do this
  // search operation in nlogn time vs n^2 time. In this case, adding
  // all of the data in is an O(nlogn) operation.
  const payoutObj: PayoutInfo = {};
  for (const doc of docs) {
    const documentData = doc.data();
    const { alias, author, questions } = documentData;
    const { votes } = questions[0];
    if (!(author in payoutObj)) {
      payoutObj[documentData.author] = {
        pollsPublished: 0,
        dollarsPaidOut: 0,
        alias: documentData.alias === undefined ? "N/A" : alias,
      };
    }

    const { dollarsPaidOut, pollsPublished } = payoutObj[documentData.author];
    payoutObj[author] = {
      pollsPublished: pollsPublished + 1,
      alias: alias === undefined ? "N/A" : alias,
      dollarsPaidOut: dollarsPaidOut + votes.length * 0.25,
    };
  }

  // Adding all of these values from the map into a list takes O(n) time.
  // Since the last operation is O(nlogn), this keeps the runtime at O(nlogn).
  // The alternative would be to use filter, findIndex, etc, which would be
  // less code, but would take O(n^2) time.
  const toReturn: PayoutInfoListItem[] = [];
  for (const author in payoutObj) {
    const { alias, dollarsPaidOut, pollsPublished } = payoutObj[author];
    toReturn.push({ author, alias, dollarsPaidOut, pollsPublished });
  }

  return toReturn;
};

/*
  This gets all of the published polls from the firestore database.
  Once the userbase bulks up, we're going to want to find another
  rendering/fetching solution. (can trigger batch fetching on scroll).
*/
const getAllPublishedPolls = async (): Promise<PublishedPollWithID[]> => {
  return (await getDocs(collection(db, "published"))).docs.map((doc) =>
    parsePublishedPoll(doc)
  );
};

const updateUserAlias = async (id: string, alias: string) => {
  const q = query(collection(db, "users"), where("id", "==", id), limit(1));
  const docRef = (await getDocs(q)).docs[0];
  await updateDoc(doc(db, `users/${docRef.id}`), {
    alias,
  });
};
/*-------------------------------------------------*/

export {
  auth,
  denyUser,
  userIsAdmin,
  getUserData,
  approveUser,
  getPayoutInfo,
  getFilterData,
  getAllUserPolls,
  updateUserAlias,
  userIsSuperAdmin,
  getAllPollCreators,
  getAllPublishedPolls,
  getPublishedPollByID,
  getUsersSeekingApproval,
  getFilterDataForQuestion,
  getPublishedPollsForUser,
  getAllUsersData
};
export type {
  PayoutInfo,
  FilteredInfo,
  FilteredQuestionInfo,
  PayoutInfoListItem,
  PublishedPollWithID,
};
