import "../styles/PollBreakdown.css";
import { csv } from "d3-fetch";
import { scaleQuantize } from "d3-scale";
import Spacer from "../components/Spacer";
import { useState, useEffect } from "react";
import { PieChart } from "react-minimal-pie-chart";
import cityToCounty from "../constants/cityToCounty.json";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import {CSVLink} from 'react-csv';
import {
  FilteredInfo,
  PublishedPollWithID,
  getFilterData,
  getUserData,
  getPublishedPollByID,
} from "../firebase";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { get } from "https";
import { useRef } from "react";
import React from "react";
import Download from "react-csv/components/Download";
import { Bar } from '@visx/shape';
import { scaleLinear, scaleBand } from '@visx/scale';
import { Group } from '@visx/group';
import { AxisBottom, AxisLeft } from '@visx/axis';

/* 
  For now, this simply takes any string with any number value as a valid 
  entry in the object. For now, it's okay to keep it this simple, but when
  we update the location constants, this must end up changing. 
*/
// interface StateTally {
//   [key: string]: number;
// }

interface PieChartDataEntry {
  title: string;
  color: string;
  value: number;
}

const colors = [
  "#0E606B",
  "#1697A6",
  "#FFC24B",
  "#a7c957",
  "#FFB3AE",
  "#F47068",
];

interface PieChartDataEntry {
  color: string;
  title: string;
  value: number;
}

const VoterPieCharts = ({ data }: { data: any }) => {
  function filterAndMapVotesData(
    dataArray: any[],
    colors: string[]
  ): PieChartDataEntry[] {
    return dataArray.map((item: any, index: number) => ({
      color: colors[index],
      title: item.label,
      value: item.votes,
    }));
  }

  const containerStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "300px", // Set a fixed width for the container
  };

  const legendContainerStyle: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    flexDirection: "row", 
    width: "100%", 
    justifyContent: "flex-end", // Add this to align content to the right
    flex: 1,  // Takes half the space
};

  const pieChartContainerStyle: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end", // Pushes the pie chart to the right
    flex: 1,  // Takes half the space
  };
  

  const pieChartStyle: React.CSSProperties = {
    height: "30vh",
    fontSize: "7.5px",
    width: "min-content",  
    flex: 1,  // Takes half the space
  };

  const totalVotes = Object.values(data.votes).flat().reduce((total: number, entry: any) => total + entry.votes, 0);

  const PieChartWithLegend = ({ title, data }: { title: string, data: PieChartDataEntry[] }) => {
    // Calculate the sum of votes for the provided data
    const sumOfVotes = data.reduce((total, entry) => total + entry.value, 0);
    
    return (
      <div style={legendContainerStyle}>
          <div style={containerStyle}>
              <p className="PBSubheading" style={{ textAlign: "center" }}>{title}</p>
              <PieChart
                  style={pieChartStyle}
                  data={data}
                  labelStyle={{ fill: "#ffffff", textShadow: "1px 1px 2px black" }}
                  labelPosition={60}
                  label={({ dataEntry }) => `${(dataEntry.value / sumOfVotes * 100).toFixed(2)}%`}
              />
          </div>
          <Spacer width="50px" height="100%" />
          <div style={{ alignSelf: 'flex-start' }}>
              {data.map((dataEntry, index) => (
            <div key={index} style={{
              display: "flex", alignItems: "center",
              marginBottom: index !== data.length - 1 ? "7.5px" : 0
            }}>
              <div style={{
                width: "20px", height: "20px", borderRadius: "5px",
                backgroundColor: dataEntry.color
              }} />
              <Spacer width="10px" height="100%" />
              <p style={{ margin: 0, color: "#FFF", fontSize: "1vmax", fontFamily: "Lato" }}>
                {dataEntry.title + ` ${(dataEntry.value / sumOfVotes * 100).toFixed(2)}%`}
              </p>
            </div>
          ))}
        </div>
      </div>
    );
  }

  const categories = [
    "gender", "educationLevel", "hispanicOrLatino", "incomeLevel",
    "lgbtq", "livingSituation", "occupation", "pet",
    "politicalAffiliation", "race", "religion", "school"
  ];

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        {categories.map((category, idx) => (
            <div key={idx} style={pieChartContainerStyle}>
                <PieChartWithLegend
                    title={`Votes by ${category.charAt(0).toUpperCase() + category.slice(1)}`}
                    data={filterAndMapVotesData(data.votes[category], colors)}
                />
            </div>
        ))}
    </div>
);
};

const VoterBarCharts = ({ data, selectedQuestion }: { data: QuestionObject, selectedQuestion: string | null }) => {
  
  if (!selectedQuestion) return null;

  // Convert answers into barData
  const barData = data.answers.map((answer: AnswerObject) => {
    const totalVotes = Object.values(answer.votes).reduce((acc, voteArray) => acc + voteArray.length, 0);
    return {
      label: answer.answer,
      value: totalVotes,
    };
  });

  const width = 800; // Doubled the size
  const height = 600; // Doubled the size
  const margin = { top: 40, right: 40, bottom: 100, left: 80 }; // Adjusted margins proportionally
  const color = "#853b30"; // Hex color

  const xScale = scaleBand({
    domain: barData.map(d => d.label),
    range: [margin.left, width - margin.right],
    padding: 0.3,
  });

  const yScale = scaleLinear({
    domain: [0, Math.max(...barData.map(d => d.value))],
    range: [height - margin.bottom, margin.top],
  });

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
      <div style={{ backgroundColor: 'white', borderRadius: '15px', width: '60%', padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <p className="PBSubheading" style={{ textAlign: 'center', margin: '20px 0', color, fontSize: '1.5em' }}>
            {`Voting Breakdown: ${selectedQuestion}`}
        </p>
        <svg width={width} height={height}>
          <Group>
            {barData.map(d => (
              <Bar
                key={d.label}
                x={xScale(d.label)}
                y={yScale(d.value)}
                height={height - margin.bottom - yScale(d.value)}
                width={xScale.bandwidth()}  
                fill={color}
              />
            ))}
            <AxisBottom
              top={height - margin.bottom}
              scale={xScale}
              stroke={color}
              strokeWidth={2}
              tickStroke={color}
              tickLabelProps={() => ({
                fill: color,
                fontSize: 16, // Increased font size
                textAnchor: 'middle',
              })}
            />
            <AxisLeft
              left={margin.left}
              scale={yScale}
              stroke={color}
              strokeWidth={2}
              tickStroke={color}
              tickLabelProps={() => ({
                fill: color,
                fontSize: 16, // Increased font size
                textAnchor: 'end',
                dy: '0.32em',
              })}
            />
          </Group>
        </svg>
      </div>
    </div>
  );
};




/* 
  This is the demographic breakdown for responding users. For the entire
  poll, this shows how many users have answered it, how many have liked 
  it, and the demographics of those users.
*/
const DemographicBreakdown = ({
  numVotes,
  numLikes,
  filterData,
}: {
  numVotes: number;
  numLikes: number;
  filterData: FilteredInfo;
}) => {
  const [idTallies, setIDTallies] = useState<any>();
  const [maxLikes, setMaxLikes] = useState<number>();
  const [maxVotes, setMaxVotes] = useState<number>();
  const [countyNumbers, setCountyNumbers] = useState<any>();
  const [likesModalVisible, setLikesModalVisible] = useState(false);
  const [votesModalVisible, setVotesModalVisible] = useState(false);
  const [otherDataForCharts, setOtherDataForCharts] = useState<any>();
  const [votesStateData, setVotesStateData] = useState<PieChartDataEntry[]>([]);
  const [likesStateData, setLikesStateData] = useState<PieChartDataEntry[]>([]);
  const [focusedLikeInfo, setFocusedLikeInfo] = useState<{
    county: string;
    likes: number;
  }>();
  const [focusedVoteInfo, setFocusedVoteInfo] = useState<{
    county: string;
    votes: number;
  }>();
  const [likesCountyData, setLikesCountyData] = useState<PieChartDataEntry[]>(
    []
  );
  const [votesCountyData, setVotesCountyData] = useState<PieChartDataEntry[]>(
    []
  );
  const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/counties-10m.json";
  const range: any[] = [
    "#ffedea",
    "#ffcec5",
    "#ffad9f",
    "#ff8a75",
    "#ff5533",
    "#e2492d",
    "#be3d26",
    "#9a311f",
    "#782618",
  ];

  const likesColorScale =
    maxLikes === undefined
      ? scaleQuantize().range(range)
      : scaleQuantize().domain([0, maxLikes]).range(range);

  const votesColorScale =
    maxVotes === undefined
      ? scaleQuantize().range(range)
      : scaleQuantize().domain([0, maxVotes]).range(range);

  useEffect(() => {
    const tempChartData: any = { likes: {}, votes: {} };
    for (const key in filterData) {
      if (!["state", "country", "city"].includes(key)) {
        const options = filterData[key];
        if (!(key in tempChartData)) {
          tempChartData["likes"][key] = [];
          tempChartData["votes"][key] = [];
        }
        for (const item of options) {
          const { likes, votes } = item;
          if (key === "hispanicOrLatino") {
            const label = item[key] ? "Hispanic" : "Not Hispanic";
            tempChartData["likes"][key].push({ likes, label });
            tempChartData["votes"][key].push({ votes, label });
          }
          if (key === "lgbtq") {
            const label = item[key] ? "Lgbtq" : "Not Lgbtq";
            tempChartData["likes"][key].push({ likes, label });
            tempChartData["votes"][key].push({ votes, label });
          }
          if (!["hispanicOrLatino", "lgbtq"].includes(key)) {
            const label = item[key];
            tempChartData["likes"][key].push({ likes, label });
            tempChartData["votes"][key].push({ votes, label });
          }
        }
      }
    }
    setOtherDataForCharts(tempChartData);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    csv("/county_numbers.csv").then((data) => setCountyNumbers(data));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (countyNumbers !== undefined) {
      const idCounts: any = {};
      let maxLikes = 0;
      let maxVotes = 0;
      for (const item of filterData["city"]) {
        const { city } = item;
        const cityMapper: { [key: string]: string[] } = cityToCounty;
        for (const key in cityMapper) {
          if (cityMapper[key].includes(city)) {
            const cur = countyNumbers.find((s: any) => s.name === key);
            if (!(cur.id in idCounts))
              idCounts[cur.id] = { votes: 0, likes: 0 };
            idCounts[cur.id].votes += item.votes;
            idCounts[cur.id].likes += item.likes;

            if (idCounts[cur.id].likes > maxLikes)
              maxLikes = idCounts[cur.id].likes;
            if (idCounts[cur.id].votes > maxVotes)
              maxVotes = idCounts[cur.id].votes;

            break;
          }
        }
      }
      setMaxLikes(maxLikes + 1);
      setMaxVotes(maxVotes + 1);

      const colors = [
        "#FFC24B",
        "#0E606B",
        "#1697A6",
        "#a7c957",
        "#FFB3AE",
        "#F47068",
      ];
      const tmpCountyLikes: PieChartDataEntry[] = [];
      const tmpCountyVotes: PieChartDataEntry[] = [];
      const stateLikesObject: { [key: string]: number } = {};
      const stateVotesObject: { [key: string]: number } = {};
      for (const key in idCounts) {
        const { likes, votes } = idCounts[key];
        const title = countyNumbers.find((s: any) => s.id === key).name;
        if (likes > 0) {
          tmpCountyLikes.push({
            title,
            color: "#FFF",
            value: likes,
          });

          const splitted = title.split(",");
          let state = splitted[0];
          if (splitted.length > 1) state = splitted[1];
          if (!(state in stateLikesObject)) stateLikesObject[state] = 0;
          stateLikesObject[state] += likes;
        }

        if (votes > 0) {
          tmpCountyVotes.push({ title, color: "#FFF", value: votes });

          const splitted = title.split(",");
          let state = splitted[0];
          if (splitted.length > 1) state = splitted[1];
          if (!(state in stateVotesObject)) stateVotesObject[state] = 0;
          stateVotesObject[state] += votes;
        }
      }

      const tmpStateLikes: PieChartDataEntry[] = [];
      for (const key in stateLikesObject) {
        tmpStateLikes.push({
          title: key,
          color: "#FFF",
          value: stateLikesObject[key],
        });
      }

      const tmpStateVotes: PieChartDataEntry[] = [];
      for (const key in stateVotesObject) {
        tmpStateVotes.push({
          title: key,
          color: "#FFF",
          value: stateVotesObject[key],
        });
      }

      tmpStateLikes.sort((a, b) => b.value - a.value);
      const toSetStateLikes: PieChartDataEntry[] = [];
      let otherStateLikeSum: number = 0; // eslint-disable-line @typescript-eslint/no-unused-vars
      for (let i = 0; i < tmpStateLikes.length; i++) {
        if (toSetStateLikes.length === 9)
          otherStateLikeSum += tmpStateLikes[i].value;
        else {
          tmpStateLikes[i].color = colors[i];
          toSetStateLikes.push(tmpStateLikes[i]);
        }
      }

      tmpStateVotes.sort((a, b) => b.value - a.value);
      const toSetStateVotes: PieChartDataEntry[] = [];
      let otherStateVoteSum = 0; // eslint-disable-line @typescript-eslint/no-unused-vars
      for (let i = 0; i < tmpStateVotes.length; i++) {
        if (toSetStateVotes.length === 9)
          otherStateVoteSum += tmpStateVotes[i].value;
        else {
          tmpStateVotes[i].color = colors[i];
          toSetStateVotes.push(tmpStateVotes[i]);
        }
      }

      tmpCountyLikes.sort((a, b) => b.value - a.value);
      const toSetCountyLikes: PieChartDataEntry[] = [];
      let otherCountyLikeSum = 0;
      for (let i = 0; i < tmpCountyLikes.length; i++) {
        if (toSetCountyLikes.length === 9)
          otherCountyLikeSum += tmpCountyLikes[i].value;
        else {
          tmpCountyLikes[i].color = colors[i];
          toSetCountyLikes.push(tmpCountyLikes[i]);
        }
      }
      if (otherCountyLikeSum > 0) {
        toSetCountyLikes.push({
          title: "other",
          value: otherCountyLikeSum,
          color: colors[9],
        });
      }

      tmpCountyVotes.sort((a, b) => b.value - a.value);
      const toSetCountyVotes: PieChartDataEntry[] = [];
      let otherCountyVoteSum = 0;
      for (let i = 0; i < tmpCountyVotes.length; i++) {
        if (toSetCountyVotes.length === 9)
          otherCountyVoteSum += tmpCountyVotes[i].value;
        else {
          tmpCountyVotes[i].color = colors[i];
          toSetCountyVotes.push(tmpCountyVotes[i]);
        }
      }
      if (otherCountyVoteSum > 0) {
        toSetCountyVotes.push({
          title: "other",
          value: otherCountyVoteSum,
          color: colors[9],
        });
      }

      setVotesStateData(toSetStateVotes);
      setVotesCountyData(toSetCountyVotes);
      setLikesStateData(toSetStateLikes);
      setLikesCountyData(toSetCountyLikes);
      setIDTallies(idCounts);
    }
  }, [countyNumbers]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="PBCenterView" style={{ pageBreakInside: "avoid" }}>
      <Spacer width="100vw" height="25px" />
      <p className="PBTitle">Demographic Breakdown of Responding Users</p>
      <Spacer width="100vw" height="50px" />
      <img src="/RRlogo.png" alt="Red Rover Logo" width="250" height="250" />
      <div className="PBSpaceFiller">
        {numLikes !== 0 &&
          idTallies !== undefined &&
          otherDataForCharts !== undefined && (
            <>
              <Spacer width="100vw" height="25px" />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
              </div>
              <Spacer width="100vw" height={25} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  width: "100vw",
                  alignItems: "center",
                }}
              >
              </div>
              <Spacer width="100vw" height="25px" />
              <p
                className="PBHeading"
                style={{ alignSelf: "center", margin: 0 }}
              >
                Votes Breakdown
              </p>
              <Spacer width="100vw" height="25px" />
              <p className="PBSubheading" style={{ alignSelf: "center" }}>
                Total Number of Votes: {numVotes}
              </p>
              <Spacer width="100vw" height="25px" />
              <div className="PBContainer" style={{ width: "50vw" }}>
                <div
                  className="PBContainerMapModal"
                  onClick={() => {
                    setVotesModalVisible(false);
                    setFocusedVoteInfo(undefined);
                  }}
                  id={votesModalVisible ? "PBContainerMapModalVisible" : ""}
                >
                  {focusedVoteInfo !== undefined && (
                    <div className="CountyLikeSnapshot">
                      <p className="CountyLikeSnapshotText">
                        {focusedVoteInfo.county}
                      </p>
                      <p
                        className="CountyLikeSnapshotText"
                        style={{ textAlign: "center" }}
                      >
                        Votes:{" "}
                        {focusedVoteInfo.votes === -1
                          ? 0
                          : focusedVoteInfo.votes}
                      </p>
                    </div>
                  )}
                </div>
                <p
                  className="PBSubheading"
                  style={{ color: "#853b30", marginTop: 10 }}
                >
                  Location
                </p>
                <Spacer width="100%" height="12.5px" />
                <p
                  className="PBSubheading"
                  style={{ fontSize: "1vmax", color: "#853b30" }}
                >
                  (by county)
                </p>
                <ComposableMap projection="geoAlbersUsa">
                  <Geographies geography={geoUrl}>
                    {({ geographies }) =>
                      geographies.map((geo) => {
                        const cur = countyNumbers.find(
                          (s: any) => s.id === geo.id
                        );
                        const fill: any = votesColorScale(
                          cur &&
                            cur.id in idTallies &&
                            idTallies[cur.id]["votes"] > 0
                            ? idTallies[cur.id]["votes"]
                            : "#EEE"
                        );

                        return (
                          <Geography
                            onClick={() => {
                              setVotesModalVisible(true);
                              setFocusedVoteInfo({
                                county: cur.name,
                                votes:
                                  cur && cur.id in idTallies
                                    ? idTallies[cur.id]["votes"]
                                    : -1,
                              });
                            }}
                            key={geo.rsmKey}
                            geography={geo}
                            fill={fill}
                          />
                        );
                      })
                    }
                  </Geographies>
                </ComposableMap>
              </div>
              <Spacer width="100vw" height="150px" />
              <Spacer width="100vw" height="25px" />
              <VoterPieCharts data={otherDataForCharts} />
              <Spacer width="100vw" height="150px" />
            </>
          )}
      </div>
    </div>
  );
};

interface VoteObject {
  votes: number;
  label: string;
}

interface AnswerObject {
  answer: string;
  votes: { [key: string]: VoteObject[] };
}

interface QuestionObject {
  question: string;
  answers: AnswerObject[];
}

async function getQuestionPieChartData(
  pollData: PublishedPollWithID
): Promise<QuestionObject[]> {
  const demographicKeys = [
    "educationLevel",
    "gender",
    "hispanicOrLatino",
    "incomeLevel",
    "lgbtq",
    "livingSituation",
    "occupation",
    "pet",
    "politicalAffiliation",
    "race",
    "religion",
    "school",
    "state",
  ];
  const questionObjects: QuestionObject[] = [];

  for (const question of pollData.questions) {
    const answerObjects: AnswerObject[] = [];

    for (const vote of question.votes) {
      const userID = vote.userID;
      const userData = await getUserData(userID);
      const demographics: string[] = demographicKeys.map((key) => {
        if(userData[key] === 'Yes, both') {
          return userData[key] = 'Yes. Both';
        }
        if (key === "hispanicOrLatino") {
          return userData[key] ? "Hispanic" : "Not Hispanic";
        } else if (key === "lgbtq") {
          return userData[key] ? "LGBTQIA+" : "Not LGBTQIA+";
        } else {
          return userData[key];
        }
      });

      const answer = vote.answer;
      const answerObject = answerObjects.find((obj) => obj.answer === answer);
      if (answerObject) {
        demographics.forEach((demo, idx) => {
          const votes = answerObject.votes[demographicKeys[idx]];
          if (votes) {
            votes.push({ label: demo, votes: 1 });
          } else {
            answerObject.votes[demographicKeys[idx]] = [
              { label: demo, votes: 1 },
            ];
          }
        });
      } else {
        const votes: { [key: string]: VoteObject[] } = {};
        demographics.forEach((demo, idx) => {
          votes[demographicKeys[idx]] = [{ label: demo, votes: 1 }];
        });
        answerObjects.push({ answer, votes });
      }
    }

    questionObjects.push({
      question: question.question,
      answers: answerObjects,
    });
  }

  return questionObjects;
}

function getCSVData(questionObjects: any) {
  const csvData: object[] = [];

  for (const question of questionObjects) {
    const { question: questionText, answers } = question;

    for (const answer of answers) {
      const { answer: answerText, votes } = answer;

      if(votes.educationLevel.length === 1) {
        const row: { [key: string]: any } = { question: questionText, answer: answerText };
      for (const key in votes) {
        const demographicVotes = votes[key];
        const demographicLabels: string[] = [];

        for (const demographicVote of demographicVotes) {
          const { label, votes: numVotes } = demographicVote;
          demographicLabels.push(label);
        }
        row[key] = demographicLabels.join(", ");
      }
      csvData.push(row);
    } else {
      const voteCount = votes.educationLevel.length;
        const voteKeys = Object.keys(votes);
        const rows: { [key: string]: any }[] = [];

        for (let i = 0; i < voteCount; i++) {
          const row: { [key: string]: any } = { question: questionText, answer: answerText };

          for (const key of voteKeys) {
            const demographicVotes = votes[key];
            const demographicLabels: string[] = [];
            let count = 0;
            for (const demographicVote of demographicVotes) {
              const { label } = demographicVote;
              const labelParts = label.split(', ');
              if (count === i) {
                demographicLabels.push(labelParts);
              }
              count = (count + 1) % voteCount;
            }

            row[key] = demographicLabels.join(', ');
          }

          rows.push(row);
        }

        csvData.push(...rows);
      }
    }
  }

  return csvData;
}



/* add pie charts for quesiton
  pass in poll data
  polldata !== undefined &&
    map over questions and call respective componenets

  return pie charts for a single question
*/

export async function getPivotTableData(
  pollData: PublishedPollWithID
): Promise<string[][]> {
  const userIDs: string[] = [];

  pollData.questions.forEach((question) => {
    question.votes.forEach((vote) => {
      const userID = vote.userID;
      if (!userIDs.includes(userID)) {
        userIDs.push(userID);
      }
    });
  });

  const userAnswers: string[][] = [];

  const demographicKeys = [
    "city",
    "country",
    "educationLevel",
    "gender",
    "hispanicOrLatino",
    "incomeLevel",
    "lgbtq",
    "livingSituation",
    "occupation",
    "pet",
    "politicalAffiliation",
    "race",
    "religion",
    "school",
    "state",
  ];

  for (const userID of userIDs) {
    const userData = await getUserData(userID);
    const answer = pollData.questions[0].votes.find(
      (vote) => vote.userID === userID
    )?.answer;
    const demographicData = demographicKeys.map((key) => {
      if (key === "hispanicOrLatino") {
        return userData[key] ? "Hispanic" : "Not Hispanic";
      } else if (key === "lgbtq") {
        return userData[key] ? "LGBTQIA+" : "Not LGBTQIA+";
      } else {
        return userData[key];
      }
    });

    userAnswers.push([answer, ...demographicData]);
  }
  userAnswers.unshift(["Age", ...demographicKeys]);
  return userAnswers;
}

/* 
  This is the poll breakdown, which can be viewed in-browser or downloaded
  as a PDF. While fetching the data, it shows a loading screen, and displays
  an error message if the corresponding poll information isn't found.
*/
export default function PollBreakdown() {
  const [pollID, setPollID] = useState<string | -1>();
  const [filterData, setFilterData] = useState<FilteredInfo>();
  const [pollData, setPollData] = useState<PublishedPollWithID | -1>();
  const [isLoading, setIsLoading] = useState(true);
  const [questionData, setQuestionData] = useState<QuestionObject[]>([]);
  const [excelData, setExcelData] = useState<any[]>([]);
  const [selectedQuestion, setSelectedQuestion] = useState<string | null>(null);
  const [selectedAnswer, setSelectedAnswer] = useState<string | null>(null);
  const [availableAnswers, setAvailableAnswers] = useState<string[]>([]);

  useEffect(() => {
    // strip the protocol off of the URL
    const url = window.location.href.split("//")[1];
    console.log("loading");
    if (url.length <= 1) setPollID(-1);
    else setPollID(url.split("/")[1]);
  }, []);

  useEffect(() => {
    if (pollID !== undefined && pollID !== -1) {
      getPublishedPollByID(pollID).then((result) =>
        setPollData(result === undefined ? -1 : result)
      );
    }
  }, [pollID]);

  useEffect(() => {
    if (pollData !== undefined && pollData !== -1) {
      getQuestionPieChartData(pollData);
      getPivotTableData(pollData);
      getFilterData(pollData.questions[0].votes, pollData.likes).then(
        (result) => {
          setFilterData(result);
          setIsLoading(false); // Set loading state to false after data is loaded
        }
      );
    }
  }, [pollData]);

  useEffect(() => {
    if (filterData !== undefined) {
    }
  }, [filterData]);

  useEffect(() => {
    async function fetchData() {
      if (pollData !== undefined && pollData !== -1) {
        const result = await getQuestionPieChartData(pollData);
        const excelExport = getCSVData(result);
        setExcelData(excelExport);
        setQuestionData(result);
        setIsLoading(false);
      }
    }

    fetchData(); // eslint-disable-next-line
  }, [pollData]);

  const pdfRef = useRef<HTMLDivElement>(null);
  // if the URL is invalid or the poll doesn't exist,
  // return an error message.
  if (pollID === -1 || pollData === -1) {
    return <div>failure!</div>;
  }

  const downloadPDF = async () => {
    const mainElement = document.getElementById("PollBreakdownMainContainer");
  
    if (!mainElement) {
      return; // Return early if the "main" element is not found
    }
  
    html2canvas(mainElement).then((canvas) => {
      var contentWidth = canvas.width;
      var contentHeight = canvas.height;
  
      // Rest of the code goes here...
      var pageHeight = contentWidth / 592.28 * 841.89;
      //html page height without pdf generation
      var leftHeight = contentHeight;
      //Page offset
      var position = 0;
      //a4 paper size [595.28841.89], width and height of image in pdf of canvas generated by html page
      var imgWidth = 595.28; 
      var imgHeight = 592.28/contentWidth * contentHeight;
  
      //Return picture dataURL, parameters: picture format and sharpness (0-1)
      var pageData = canvas.toDataURL('image/jpeg', 1.0);
  
      //Direction is vertical by default, dimension points, format A4 [595.28841.89]
      var pdf = new jsPDF('p', 'pt', 'a4');
      console.log("its working...");

      //There are two heights to distinguish, one is the actual height of the html page, and the height of the generated pdf page (841.89)
      //When the content does not exceed the display range of one page of pdf, paging is not required
      if (leftHeight < pageHeight) {
        pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
      } else {
        while (leftHeight > 0) {
          pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight);
          leftHeight -= pageHeight;
          position -= 841.89;
          //Avoid adding blank pages
          if (leftHeight > 0) {
            pdf.addPage();
            console.log("adding page...");
          }
        }
      }
      pdf.save('stone.pdf');
      console.log("done...");

    });
  }

  function formatChartData(data: AnswerObject) {
    const voteData: { [key: string]: VoteObject[] } = data.votes;
  
    for (const category in voteData) {
      const transformedData: VoteObject[] = [];
      const voteItems = voteData[category];
  
      for (const item of voteItems) {
        const existingItem = transformedData.find((d) => d.label === item.label);
  
        if (existingItem) {
          existingItem.votes += item.votes;
        } else {
          transformedData.push({ label: item.label, votes: item.votes });
        }
      }
  
      voteData[category] = transformedData;
    }
  
    return data;
  }
  

  return (
    <div id="PollBreakdownMainContainer" ref={pdfRef}>
      <Spacer width="100vw" height="25px" />
      <CSVLink data={excelData} filename="exported_data.csv" className="export-button">
      Export CSV
    </CSVLink>
    <Spacer width="100vw" height="25px" />
      {/* <button className="export-button" onClick={downloadPDF}>Download PDF</button> */}
      {isLoading && (
        <div className="loader">
          <svg
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
            fill="#fff"
          >
            <circle cx="50" cy="50" r="20">
              <animate
                attributeName="r"
                from="0"
                to="20"
                dur="1s"
                repeatCount="indefinite"
              />
              <animate
                attributeName="fill-opacity"
                from="1"
                to="0"
                dur="1s"
                repeatCount="indefinite"
              />
            </circle>
          </svg>
        </div>
      )}
      {!isLoading && pollData !== undefined && filterData !== undefined && (
        <div>
          <DemographicBreakdown
        filterData={filterData}
        numLikes={pollData.likes.length}
        numVotes={pollData.questions[0].votes.length}
      />
      <Spacer width="100vw" height="25px" />

    <Spacer width="100vw" height="25px" />
    <select
  className="select-box"
  value={selectedQuestion || ''}
  onChange={(e) => {
    setSelectedQuestion(e.target.value);
    const qData = questionData.find(q => q.question === e.target.value);
    if (qData) {
      setAvailableAnswers(qData.answers.map(a => a.answer));
      setSelectedAnswer(qData.answers[0]?.answer || null);
    }
  }}
>
  <option value=''>Select a question...</option>
  {questionData.map((question) => (
    <option key={question.question} value={question.question}>
      {question.question}
    </option>
  ))}
</select>

<Spacer width="100vw" height="25px" />

<select
  className="select-box"
  value={selectedAnswer || ''}
  onChange={(e) => setSelectedAnswer(e.target.value)}
>
  <option value=''>Select an answer...</option>
  {availableAnswers.map(answer => (
    <option key={answer} value={answer}>
      {answer}
    </option>
  ))}
</select>

      {questionData
        .filter((q) => !selectedQuestion || q.question === selectedQuestion)
        .map((question) => (
          <div key={question.question}>
            <Spacer width="100vw" height="25px" />
            <p className="PBHeading" style={{ alignSelf: "center", margin: 0, marginLeft: "2.5vw" }}>
              Question: {question.question}
            </p>
            <Spacer width="100vw" height="25px" />
            <VoterBarCharts data={question} selectedQuestion={selectedQuestion} />
            {question.answers
              .filter((a) => !selectedAnswer || a.answer === selectedAnswer)
              .map((answer) => (
                <div key={answer.answer}>
                  <Spacer width="100vw" height="25px" />
                  <p className="PBSubheading" style={{ alignSelf: "center", marginLeft: "2.5vw" }}>
                    Answer: {answer.answer}
                  </p>
                  <Spacer width="100vw" height="25px" />
                  {/* Include VoterBarCharts below the Pie Chart */}
                  
                  <VoterPieCharts data={formatChartData(answer)}></VoterPieCharts>

                </div>
              ))}
          </div>
        ))
      }
      <Spacer width="100vw" height="50px" />
        </div>
      )}
    </div>
  );
}
export const userAnswers = [];
