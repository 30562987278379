import "../styles/EmployeeScreen.css";
import Spacer from "../components/Spacer";
import LoadingScreen from "../components/LoadingScreen";
import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { DocumentData } from "firebase/firestore";
import {
  PayoutInfoListItem,
  PublishedPollWithID,
  approveUser,
  auth,
  denyUser,
  getAllPollCreators,
  getAllPublishedPolls,
  getPayoutInfo,
  getPublishedPollsForUser,
  getUsersSeekingApproval,
  updateUserAlias,
  userIsAdmin,
} from "../firebase";
import { User, signOut } from "firebase/auth";
import moment from "moment";
import { formatPhoneNumber } from "../utils/phone";
import { formatDate } from "../utils/date";
import { ToastContainer, toast } from "react-toastify";
import { toastStyle } from "./Auth";
import DemographicsDisplay from "../components/DemographicsDisplay";
type NavBarOption =
  | "Financials"
  | "Polls"
  | "Settings"
  | "ViewUsers"
  | "Demographics";

/* 
  This is the nav bar that's shown at the top of the home screen when
  the user is logged in. Each button is just a text for now, with a 
  small animation. When each button is clicked on, it takes the user
  to a corresponding screen for that button.

  TODO: condense buttons into their own components for simpler reading
*/
const NavBar = ({
  option,
  setOption,
  isEmployee,
}: {
  option: NavBarOption;
  isEmployee: boolean;
  setOption: React.Dispatch<React.SetStateAction<NavBarOption>>;
}) => {
  return (
    <div id="EmployeeNavBar">
      {/* financials screen button */}
      {isEmployee && (
        <div
          onClick={() => setOption("Financials")}
          className="NavBarTextContainer"
        >
          <p className="NavBarText" unselectable="on">
            View Financials
          </p>
          <Spacer width="100%" height="20px" />
          <div
            className="CollapsedUnderline"
            id={option === "Financials" ? "AnimatedUnderline" : ""}
          />
        </div>
      )}

      {/* manage polls screen button */}
      <div onClick={() => setOption("Polls")} className="NavBarTextContianer">
        <p className="NavBarText" unselectable="on">
          View Polls
        </p>
        <Spacer width="100%" height="20px" />
        <div
          className="CollapsedUnderline"
          id={option === "Polls" ? "AnimatedUnderline" : ""}
        />
      </div>

      {/* view general user information and reset aliases */}
      <div
        className="NavBarTextContainer"
        onClick={() => {
          setOption("ViewUsers");
        }}
      >
        <p className="NavBarText" unselectable="on">
          View Users
        </p>
        <Spacer width="100%" height="20px" />
        <div
          className="CollapsedUnderline"
          id={option === "ViewUsers" ? "AnimatedUnderline" : ""}
        />
      </div>
      <div
        onClick={() => setOption("Demographics")}
        className="NavBarTextContainer"
      >
        <p className="NavBarText" unselectable="on">
          View Demographics
        </p>
        <Spacer width="100%" height="20px" />
        <div
          className="CollapsedUnderline"
          id={option === "Demographics" ? "AnimatedUnderline" : ""}
        />
      </div>

      {/* settings screen button */}
      <div
        onClick={() => setOption("Settings")}
        className="NavBarTextContainer"
      >
        <p className="NavBarText" unselectable="on">
          Log In / Log Out
        </p>
        <Spacer width="100%" height="20px" />
        <div
          className="CollapsedUnderline"
          id={option === "Settings" ? "AnimatedUnderline" : ""}
        />
      </div>
    </div>
  );
};

const Demographics = () => {
  return (
    <div className="ESContainer">
      <Spacer width="100vw" height="5vh" />
      <p>Demographics data will be shown here.</p>
    </div>
  );
};

/*
  This is the component that encompasses the user approval process.
  It gives a phone number for the user, and allows the admin to 
  enter an alias (for now).

  TODO: extend the approval process to take more information about the 
  poll creators.
*/
const UserToApprove = ({
  last,
  index,
  userData,
  setLoading,
  seekingApproval,
  setSeekingApproval,
  updateAdminByNumber,
}: {
  last: boolean;
  index: number;
  userData: any;
  seekingApproval: DocumentData[] | undefined;
  setSeekingApproval: React.Dispatch<
    React.SetStateAction<DocumentData[] | undefined>
  >;
  updateAdminByNumber: (user: DocumentData) => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [alias, setAlias] = useState("");
  return (
    <>
      <div className="UserApprovalContainer">
        <p className="UserApprovalNumber">{userData.phoneNumber}</p>
        <Spacer width="100%" height="2.5vmin" />
        <p className="AliasPrompt">Enter an Alias</p>
        <Spacer width="100%" height="2.5vmin" />

        <input
          value={alias}
          inputMode="text"
          className="AliasInput"
          name={index.toString()}
          onChange={({ target }) => setAlias(target.value)}
        />
        <Spacer width="100%" height="2.5vmin" />

        {/* this is the container that holds the approve and deny buttons */}
        <div className="ApprovalButtonContainer">
          <button
            className="ApproveButton"
            onClick={() => {
              setLoading(true);
              approveUser(userData.id, alias).then(() => {
                userData.alias = alias;
                setAlias("");
                setSeekingApproval(
                  seekingApproval?.filter((val) => val.id !== userData.id)
                );
                updateAdminByNumber(userData as DocumentData);
                setLoading(false);
              });
            }}
          >
            <p className="ApprovalButtonText">Approve</p>
          </button>
          <Spacer width="100%" height="1vh" />
          <button
            className="DenyButton"
            onClick={() => {
              setLoading(true);
              denyUser(userData.id).then(() => {
                setAlias("");
                setSeekingApproval(
                  seekingApproval?.filter((val) => val.id !== userData.id)
                );
                setLoading(false);
              });
            }}
          >
            <p className="ApprovalButtonText">Deny</p>
          </button>
        </div>
      </div>
      {!last && (
        <>
          <Spacer width="100%" height="5vh" />
          <div
            style={{
              width: "75%",
              height: "1px",
              backgroundColor: "#FFF",
            }}
          />
          <Spacer width="100%" height="5vh" />
        </>
      )}
      {last && <Spacer width="100%" height="10vh" />}
    </>
  );
};

/*
  This is a filler screen that is shown when there are no users that need
  approval.

  TODO: make this better
*/
const NoUsersToApprove = () => {
  return (
    <>
      <p id="NothingToApprove">
        Looks like there's no more users to approve right now!
      </p>
    </>
  );
};

/* 
  This is the subscreen that is shown when the user clicks on the "Approve Users"
  button. It simply holds a list of UserToApprove components and allows the user
  to scroll through them and approve/deny them as they please. If there are no 
  users seeking approval, we simply show a placeholder screen to indicate that.
*/
const UserApproval = ({
  setLoading,
  seekingApproval,
  setSeekingApproval,
  updateAdminByNumber,
}: {
  seekingApproval: DocumentData[] | undefined;
  updateAdminByNumber: (user: DocumentData) => void;
  setSeekingApproval: React.Dispatch<
    React.SetStateAction<DocumentData[] | undefined>
  >;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  if (seekingApproval === undefined) return <></>;
  return (
    <>
      {seekingApproval.length === 0 ? (
        <NoUsersToApprove />
      ) : (
        seekingApproval.map((data, index) => {
          return (
            <UserToApprove
              key={index}
              index={index}
              userData={data}
              setLoading={setLoading}
              seekingApproval={seekingApproval}
              setSeekingApproval={setSeekingApproval}
              updateAdminByNumber={updateAdminByNumber}
              last={index === seekingApproval.length - 1}
            />
          );
        })
      )}
    </>
  );
};

/*
  This is a heading for the "View Financials" screen.
*/
const FSHeading = ({
  heading,
  subHeading,
}: {
  heading: string;
  subHeading: string;
}) => {
  return (
    <div className="FSHeadingWrapper">
      <p className="FSHeading">{heading}</p>
      <p className="FSSubHeading">{subHeading}</p>
    </div>
  );
};

/*
  This is the component that holds a financial snapshot for each user
  in the database (for now). This simply gets each creator, and tallies
  their polls and payouts based on the number of replies for each poll.

  TODO: fix this to correctly tally payouts when the feature is modified.
*/
const FinancialSnapshot = ({
  last,
  payoutData,
}: {
  last: boolean;
  payoutData: PayoutInfoListItem;
}) => {
  const { alias, author, dollarsPaidOut, pollsPublished } = payoutData;
  return (
    <>
      <div className="FinancialSnapshotContainer">
        <p className="FSAuthorName">{alias === "N/A" ? author : alias}</p>
        <Spacer width="100%" height={"2.5vh"} />
        <div className="FSHeadingContainer">
          <FSHeading
            heading="Polls Published"
            subHeading={`${pollsPublished}`}
          />
          <FSHeading
            heading="Dollars Paid Out"
            subHeading={`$${dollarsPaidOut}`}
          />
        </div>
      </div>
      {!last && (
        <>
          <Spacer width="100vw" height="5vh" />
          <div
            style={{
              width: "75vw",
              height: "2.5px",
              backgroundColor: "#FFF",
            }}
          />
          <Spacer width="100vw" height="5vh" />
        </>
      )}
      {last && <Spacer width="100%" height="10vh" />}
    </>
  );
};

/*
  This is the screen that is shown when a user clicks the "View Finanicals"
  button. It is nearly identical in structure to the "UsersToApprove" 
  component above.
*/
const Financials = ({
  setLoading,
}: {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [payoutInfo, setPayoutInfo] = useState<PayoutInfoListItem[]>([]);

  useEffect(() => {
    setLoading(true);
    getPayoutInfo().then((result) => {
      setPayoutInfo(result);
      setLoading(false);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="ESContainer">
        <Spacer width="100vw" height="5vh" />
        {payoutInfo.length === 0 ? (
          <NoUsersToApprove />
        ) : (
          payoutInfo.map((data, index) => {
            return (
              <FinancialSnapshot
                key={index}
                payoutData={data}
                last={index === payoutInfo.length - 1}
              />
            );
          })
        )}
      </div>
    </>
  );
};

/*
  This is a simple (and temporary) settings screen. For now, it just 
  allows the user to click a button to log out.
*/
const Settings = ({
  setUser,
}: {
  setUser: React.Dispatch<React.SetStateAction<User | null | undefined>>;
}) => {
  return (
    <>
      <div className="ESContainer">
        <Spacer width="100%" height={"40vh"} />
        <button
          id="LogOutButton"
          onClick={() => {
            signOut(auth);
            setUser(null);
          }}
        >
          <p id="LogOutButtonText">Log Out</p>
        </button>
        <Spacer width="100%" height={"40vh"} />
      </div>
    </>
  );
};

/*
  This is the component that shows some preview data for a published poll, 
  and allows an employee to get a PDF breakdown of the responses and 
  demographics.
*/
const PublishedPollSnapshot = ({
  last,
  poll,
}: {
  last: boolean;
  poll: PublishedPollWithID;
}) => {
  const { title, dateCreated } = poll.pollData;
  const { likes, questions, expirationTime } = poll;
  return (
    <>
      <div className="PublishedPollSnapshotContainer">
        <p className="PPSTitle">{title}</p>
        <Spacer width="50vw" height="2.5vh" />
        <div className="PPSInfoContainer">
          <div className="PPSHeadingContainer">
            <p className="PPSHeading">
              <p
                style={{
                  fontWeight: "bold",
                  margin: 0,
                }}
              >
                Number of questions:
              </p>
              <Spacer height="100%" width="1vw" />
              {questions.length}
            </p>
          </div>
          <div className="PPSHeadingContainer">
            <p className="PPSHeading">
              <p
                style={{
                  fontWeight: "bold",
                  margin: 0,
                }}
              >
                Number of likes:
              </p>
              <Spacer height="100%" width="1vw" />
              {likes.length}
            </p>
          </div>
          <div className="PPSHeadingContainer">
            <p className="PPSHeading">
              <p
                style={{
                  fontWeight: "bold",
                  margin: 0,
                }}
              >
                Number of responses:
              </p>
              <Spacer height="100%" width="1vw" />
              {questions[0].votes.length}
            </p>
          </div>
          <div className="PPSHeadingContainer">
            <p className="PPSHeading">
              <p
                style={{
                  fontWeight: "bold",
                  margin: 0,
                }}
              >
                Creation time:
              </p>
              <Spacer height="100%" width="1vw" />
              {moment(parseInt(dateCreated)).format("LLLL")}
            </p>
          </div>
          <div className="PPSHeadingContainer">
            <p className="PPSHeading">
              <p
                style={{
                  fontWeight: "bold",
                  margin: 0,
                }}
              >
                Expiration Time:
              </p>
              <Spacer height="100%" width="1vw" />
              {moment(parseInt(expirationTime)).format("LLLL")}
            </p>
          </div>
        </div>
        <Spacer width="50vw" height="2.5vh" />
        <p
          className="ViewPDFButton"
          onClick={() => window.open(`/${poll.id}/breakdown`, "_none")}
        >
          View PDF
        </p>
      </div>
      {!last && (
        <>
          <Spacer width="100vw" height="5vh" />
          <div
            style={{
              width: "50vw",
              height: "1.25px",
              backgroundColor: "#fff",
            }}
          />
          <Spacer width="100vw" height="5vh" />
        </>
      )}
    </>
  );
};

/*
  This screen shows each poll that is in a database, and allows an employee
  to download and view and PDF snapshot of that poll.
*/
const ViewPollInfo = ({
  setLoading,
  isEmployee,
}: {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isEmployee: boolean;
}) => {
  const [publishedPolls, setPublishedPolls] = useState<PublishedPollWithID[]>();

  useEffect(() => {
    setLoading(true);

    // for now, if the fetch fails or times out,
    // it'll just "load" forever
    if (isEmployee)
      getAllPublishedPolls().then((publishedPolls) => {
        setPublishedPolls(publishedPolls);
        setLoading(false);
      });
    else {
      const uid: string | undefined = auth.currentUser?.uid;
      if (uid !== undefined)
        getPublishedPollsForUser(uid).then((publishedPolls) => {
          setPublishedPolls(publishedPolls);
          setLoading(false);
        });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="ESContainer">
      {publishedPolls !== undefined && publishedPolls.length === 0 && (
        <p id="NoPollsToView">Uh oh! There's no polls to view right now.</p>
      )}
      {publishedPolls !== undefined && publishedPolls.length > 0 && (
        <>
          <Spacer width="100vw" height="5vh" />
          {publishedPolls.map((poll, index) => (
            <PublishedPollSnapshot
              key={index}
              poll={poll}
              last={index === publishedPolls.length - 1}
            />
          ))}
        </>
      )}
    </div>
  );
};

interface ApprovedUserData {
  last: boolean;

  id?: string;
  alias?: string;
  admin?: boolean;
  balance?: number;
  rrAdmin?: boolean;
  createdAt?: string;
  phoneNumber?: string;
  intakeSurvey?: boolean;
  seekingApproval?: boolean;
}

const AUPAlias = ({ id, alias }: { id: string; alias: string }) => {
  const [height, setHeight] = useState(0);
  const [editing, setEditing] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [currAlias, setCurrAlias] = useState(alias);
  const inputRef: MutableRefObject<HTMLInputElement | null> = useRef(null);

  useEffect(() => {
    if (!mounted && inputRef.current) {
      setHeight(inputRef.current.offsetHeight);
      setMounted(true);
    }
  }, [inputRef, mounted]);

  return (
    <div className="AUPAliasContainer">
      <input
        name={id}
        ref={inputRef}
        type="text"
        value={currAlias}
        disabled={!editing}
        className="AUPAliasInput"
        onLoad={() => setMounted(true)}
        onInput={(e) => setCurrAlias((e.target as HTMLTextAreaElement).value)}
      />
      {mounted && (
        <>
          <Spacer width="2.5vmin" height="100%" />
          <button
            style={{
              height,
              width: height,
              backgroundColor: editing ? "#853b30" : "#ffffff",
            }}
            className="AUPButton"
            onClick={() => {
              if (editing && currAlias && currAlias !== alias) {
                const newAlias = currAlias;
                updateUserAlias(id, currAlias).then(() => {
                  toast.success(
                    `User's alias updated to "${newAlias}".`,
                    toastStyle
                  );
                });
                alias = currAlias;
                setEditing(!editing);
              } else {
                setCurrAlias(alias);
                setEditing(!editing);
              }
            }}
          >
            ✏️
          </button>
        </>
      )}
    </div>
  );
};

const ApprovedUserPreview = (userData: ApprovedUserData) => {
  return (
    <>
      <div className="AUPContainer">
        <div className="AUPDescriptorContainer">
          <h1 className="AUPDescriptor">Unique ID:</h1>
          <h1 className="AUPItem">{userData.id}</h1>
        </div>
        <Spacer width="100%" height="1.25vmin" />
        <div className="AUPDescriptorContainer">
          <h1 className="AUPDescriptor">Alias (Nickname):</h1>
          {userData.id && (
            <AUPAlias id={userData.id} alias={userData.alias ?? ""} />
          )}
        </div>
        <Spacer width="100%" height="1.25vmin" />
        <div className="AUPDescriptorContainer">
          <h1 className="AUPDescriptor">Phone Number:</h1>
          <h1 className="AUPItem">
            {formatPhoneNumber(userData.phoneNumber ?? "")}
          </h1>
        </div>
        <div className="AUPDescriptorContainer">
          <h1 className="AUPDescriptor">Time Created:</h1>
          <h1 className="AUPItem">{formatDate(userData.createdAt ?? "")}</h1>
        </div>
      </div>
      {userData.last ? (
        <>
          <Spacer width="100%" height="5vmin" />
        </>
      ) : (
        <>
          <Spacer width="100%" height="5vmin" />
          <div
            style={{
              width: "calc(80% + 2.5vmin)",
              height: "1px",
              backgroundColor: "#FFF",
            }}
          />
          <Spacer width="100%" height="5vmin" />
        </>
      )}
    </>
  );
};

const ViewUsers = ({
  setLoading,
  seekingApproval,
  setSeekingApproval,
}: {
  seekingApproval: DocumentData[] | undefined;
  setSeekingApproval: React.Dispatch<
    React.SetStateAction<DocumentData[] | undefined>
  >;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [adminUsers, setAdminUsers] = useState<DocumentData[]>([]);
  const [loadingUA, setLoadingUA] = useState(false);

  const updateAdminByNumber = (user: DocumentData) => {
    if (user.phoneNumber) {
      setAdminUsers(adminUsers.concat([user]));
    }
  };

  useEffect(() => {
    setLoading(true);
    getAllPollCreators().then((result) => {
      setAdminUsers(result);
      setLoading(false);
    });
  }, [setLoading]);

  return (
    <div id="VUPanelContainer">
      <div className="VUPanel">
        <Spacer width="100%" height="2.5vmin" />
        <h1 className="VUPanelLabel">Approved Users</h1>
        <Spacer width="100%" height="2.5vmin" />
        {adminUsers.map((item, index) => (
          <ApprovedUserPreview
            key={index}
            last={index === adminUsers.length - 1}
            {...item}
          />
        ))}
      </div>
      <div className="VUPanel">
        <Spacer width="100%" height="2.5vmin" />
        <h1 className="VUPanelLabel">Seeking Approval</h1>
        <Spacer width="100%" height="2.5vmin" />
        <UserApproval
          {...{
            seekingApproval,
            setSeekingApproval,
            updateAdminByNumber,
            setLoading: setLoadingUA,
          }}
        />

        {/* 
          If this becomes too much for the user (interaction stops temporarily),
          then take this out.
        */}
        <LoadingScreen visible={loadingUA} />
      </div>
    </div>
  );
};

/*
  This is the main parent component that's displayed on the home screen 
  once the user is logged in. This simply holds a nav bar, and under it, 
  the screen that corresponds to the selected option in the navbar. By
  default, the "Approve Users" screen is loaded on first render.
*/
export default function EmployeeScreen({
  setUser,
  isEmployee,
}: {
  isEmployee: boolean;
  setUser: React.Dispatch<React.SetStateAction<User | null | undefined>>;
}) {
  const [loading, setLoading] = useState(true);
  const [admin, setAdmin] = useState<boolean>();
  const [seekingApproval, setSeekingApproval] = useState<DocumentData[]>();
  const [option, setOption] = useState<NavBarOption>(
    isEmployee ? "ViewUsers" : "Polls"
  );

  useEffect(() => {
    const str: string | undefined = auth.currentUser?.uid;
    if (str !== undefined)
      userIsAdmin(str).then((res) => {
        setAdmin(res);
      });
  }, []);

  useEffect(() => {
    if (seekingApproval === undefined) {
      getUsersSeekingApproval().then((result) => {
        setSeekingApproval(result);
        setLoading(false);
      });
    }
  }, [seekingApproval]);

  return (
    <>
      <ToastContainer
        limit={1}
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        style={{ width: "25vmax" }}
      />
      <LoadingScreen visible={loading || admin === undefined} />
      <NavBar option={option} setOption={setOption} isEmployee={isEmployee} />
      {admin &&
        (() => {
          switch (option) {
            // case "Users":
            //   return (
            //     <UserApproval
            //       setLoading={setLoading}
            //       seekingApproval={seekingApproval}
            //       setSeekingApproval={setSeekingApproval}
            //     />
            //   );
            case "Polls":
              return (
                <ViewPollInfo setLoading={setLoading} isEmployee={isEmployee} />
              );
            case "Financials":
              return isEmployee ? (
                <Financials setLoading={setLoading} />
              ) : (
                <></>
              );
            case "Settings":
              return <Settings setUser={setUser} />;
            case "ViewUsers":
              return (
                <ViewUsers
                  setLoading={setLoading}
                  seekingApproval={seekingApproval}
                  setSeekingApproval={setSeekingApproval}
                />
              );
            case "Demographics":
              return <DemographicsDisplay />;
          }
        })()}
      {!admin &&
        (() => {
          switch (option) {
            case "Settings":
              return <Settings setUser={setUser} />;
          }
        })()}
    </>
  );
}
