import React, { useEffect, useState } from 'react';
import { getAllUsersData } from "../firebase";
import PieChartComponent from './PieChartComponent';

const DemographicsDisplay: React.FC = () => {
    const [demographicsData, setDemographicsData] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        getAllUsersData().then((data: any) => {
            setDemographicsData(data);
            setLoading(false);
        });
    }, []);

    if (loading) {
        return <p>Loading...</p>;
    }

    const totalUsers = demographicsData && demographicsData.state
        ? demographicsData.state.reduce((acc: number, curr: any) => acc + curr.userID.length, 0)
        : 0;

    const demographicCategories = Object.keys(demographicsData);

    const allStates = [
        "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", 
        "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho", 
        "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", 
        "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", 
        "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", 
        "New Hampshire", "New Jersey", "New Mexico", "New York", 
        "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", 
        "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota", 
        "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", 
        "West Virginia", "Wisconsin", "Wyoming"
    ]

    const stateBreakdown = [0.0152, 0.0022, 0.0227, 0.0091, 0.1194, 0.0174, 0.0109, 0.0030, 0.0650, 0.0323, 0.0044, 0.0056, 0.0387, 0.0205, 0.0096, 0.0089, 0.0136, 0.0141, 0.0041, 0.0187, 0.0212, 0.0304, 0.0172, 0.0089, 0.0186, 0.0033, 0.0059, 0.0094, 0.0042, 0.0280, 0.0064, 0.0610, 0.0316, 0.0024, 0.0356, 0.0120, 0.0128, 0.0393, 0.0033, 0.0155, 0.0027, 0.0209, 0.0880, 0.0099, 0.0019, 0.0261, 0.0232, 0.0054, 0.0178, 0.0017
    ]

    const boxStyle = {
        backgroundColor: 'white',
        borderRadius: '10px',
        padding: '20px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        margin: '10px'
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px' }}>
            
            {/* Left Section */}
            <div style={{ ...boxStyle, flex: 1 }}>
                <h1>Total Users: {totalUsers}</h1>
                <h2>Percentage by State:</h2>
                <ul>
                    {allStates.map((state, index) => {
                        const stateData = demographicsData.state.find((s: any) => s.state === state);
                        const statePercentage = stateData ? ((stateData.userID.length / totalUsers) * 100).toFixed(2) : '0.00';
                        const expectedPercentage = (stateBreakdown[index] * 100).toFixed(2);
                        const difference = parseFloat(statePercentage) - parseFloat(expectedPercentage);
                        const sign = difference >= 0 ? '+' : '-';
                        return (
                            <li key={state}>
                                {state}: {statePercentage}% (Expected: {expectedPercentage}%, Difference: {sign}{Math.abs(difference).toFixed(2)}%)
                            </li>
                        );
                    })}
                </ul>
            </div>

            {/* Right Section */}
            <div style={{ ...boxStyle, flex: 2 }}>
                {demographicCategories.map(category => (
                    category !== 'state' && (
                        <div key={category} style={{ marginBottom: '20px' }}>
                            <h2>{category}</h2>
                            <PieChartComponent data={demographicsData[category]} label={category} />
                        </div>
                    )
                ))}
            </div>
        </div>
    );
};

export default DemographicsDisplay;
